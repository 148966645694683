import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ToolsItems from "./homepage-components/tools-items"


const toolsLeft = [
  {
    name: "Bloggers Opinions",
    text: "Tracking and measuring the performance of over 50,000 financial experts",
  },
  {
    name: "Insiders' Hot Stocks",
    text: "Follow these corporate insiders to have a new perspective of the market.",
  },
  {
    name: "News Sentiment",
    text: "Compare the amount of positive or negative news to see how much buzz a company has generated in the past week",
  },
]

const toolsRight = [
  {
    name: "Daily Analyst Ratings",
    text: "Follow top-rated financial analysts to find out their opinion about the price evolution of various stocks.",
  },
  {
    name: "Hedge Funds Activity",
    text: "See how leading fund managers have adjusted their positions on the world’s leading stocks.",
  },
]

const toolsMobile = [
  {
    name: "Bloggers Opinions",
    text: "Tracking and measuring the performance of over 50,000 financial experts",
  },
  {
    name: "Insiders' Hot Stocks",
    text: "Follow these corporate insiders to have a new perspective of the market.",
  },
  {
    name: "News Sentiment",
    text: "Compare the amount of positive or negative news to see how much buzz a company has generated in the past week",
  },
  {
    name: "Daily Analyst Ratings",
    text: "Follow top-rated financial analysts to find out their opinion about the price evolution of various stocks.",
  },
  {
    name: "Hedge Funds Activity",
    text: "See how leading fund managers have adjusted their positions on the world’s leading stocks.",
  },
]

const SectionFour = () => {
  return (
    <section className="container py-[4em] lg:py-[8em]">
      <div className="lg:bg-primaryColor lg:p-[6em] lg:text-[#fff] rounded-2xl">
        <div className="lg:flex items-center justify-between center lg:text-left">
          <div className="flex flex-col">
            <div>
              <h3 className="mb-[0]">You have the passion.</h3>
              <h2>We have the resources.</h2>
            </div>
            <div>
              <div className="flex items-center justify-center lg:justify-start min-w-[250px]">
                <StaticImage
                  src="../../images/google_play.png"
                  width={120}
                  height={39}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="appstores"
                />
                <StaticImage
                  className="rounded-lg ml-[2em]"
                  src="../../images/app_store.png"
                  width={120}
                  height={39}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="appstores"
                />
              </div>
            </div>
          </div>
          <div className="ondesktop w-[45%]">
            <p className="text-[#fff] mb-[2em]">
              Meet WebTrader – an intuitive & easy-to-use platform,
              fully-equipped with everything you need to explore the markets!
            </p>
            <p className="text-[#fff]">
              From the comfort of your home office to a sunny beach – you can
              trade from anywhere & anytime with WebTrader!
            </p>
          </div>
        </div>
      </div>
      <div className="hidden relative lg:flex items-center justify-between pt-[5em]">
        <div className="flex flex-col justify-between w-[30%]">
          {toolsLeft.map(item => {
            return (
              <div>
                <p className="uppercase blueLink mb-[1em]">{item.name}</p>
                <p className="mb-[1em]">{item.text}</p>
              </div>
            )
          })}
        </div>
        <div className="absolute top-[-10%] left-[30%] w-[40%] h-full">
          <StaticImage
            className="rounded-lg"
            src="../../images/pc-section-4.png"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="A Gatsby astronaut"
          />
        </div>
        <div className="flex flex-col justify-center text-right w-[30%]">
          {toolsRight.map(item => {
            return (
              <div>
                <p className="uppercase blueLink mb-[1em] justify-start">
                  {item.name}
                </p>
                <p className="mb-[1em]">{item.text}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="lg:hidden pt-[4em]">
        <div className="bg-[#f8f8f8] center">
          <p className="py-[1em]">Learn more about our integrated tools</p>
        </div>
        <div>
          {toolsMobile.map(toolItem => (
            <ToolsItems data={toolItem} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionFour
