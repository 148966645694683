import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

const SectionOne = () => {
    return (
      <div>
        <section className="container  py-[5em] lg:py-[8em]">
          {/* section 1 title and subtitle */}
          <div className="container ondesktop">
            <div className="text-center pb-[8em]">
              <h2>Discover New Worlds!</h2>
              <p>
                Trade CFDs on your favorite assets, from more than 2.100
                financial instruments, covering global markets.
              </p>
            </div>
          </div>
          {/* section 1 content */}
          <div>
            <div className="flex items-center justify-between">
              <div className="w-full lg:w-[50%]">
                <div className="text-center lg:text-left">
                  <h2>Trade Your Favorite Markets</h2>
                  <p className="w-full lg:w-[90%]">
                    Trade CFDs on{" "}
                    <span className="blueLink cursor-pointer">Facebook</span>,{" "}
                    <span className="blueLink cursor-pointer">Google</span>,{" "}
                    <span className="blueLink cursor-pointer">EURUSD</span>,{" "}
                    <span className="blueLink cursor-pointer">#USA500</span> or{" "}
                    <span className="blueLink cursor-pointer">Gold</span> – all
                    within our award-winning, intuitive platform.
                  </p>
                </div>
                <div className="flex items-center justify-center lg:justify-start pt-[6em] lg:pt-[10em] lg:pb-[5em]">
                  <div className="relative bg-secondaryColor rounded-lg">
                    <p className="flex flex-col text-[#fff] py-[17px] pl-[160px] pr-[15px] lg:pr-[30px] xs:pl-[125px]">
                      Enjoy Trading
                      <span className="text-[12px]">
                        with our webtrader platform
                      </span>
                    </p>
                    <div className="absolute top-[-40%] left-[10px]">
                      <StaticImage
                        src="../../images/pc-section-1.png"
                        quality={95}
                        objectFit="contain"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="hidden lg:flex items-center w-full group">
                  <a className="greyLink">Learn more about our platform</a>
                  <IoMdArrowDropdown className="-rotate-90 mr-[2em] h-7 w-7 mt-1  transition delay-0 ease-in-out group-hover:text-secondaryColor hover:fill-[#000]" />
                </div>
              </div>
              <div className="ondesktop">
                <StaticImage
                  src="../../images/ipad-section-1.png"
                  quality={95}
                  objectFit="contain"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

export default SectionOne
