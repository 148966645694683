import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiTime } from "@react-icons/all-files/bi/BiTime"

const AcademySliderMobile = () => {
  SwiperCore.use([Autoplay])

  const data = useStaticQuery(graphql`
    {
      bigImages: allFile(
        filter: {
          extension: { regex: "" }
          relativeDirectory: { eq: "academySlideMobile" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  return (
    <div className="mr-[-2em]">
      <Swiper
        className="academySlider"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        preloadImages={false}
        spaceBetween={15}
        slidesPerView={2.2}
      >
        {data.bigImages.edges.map(({ node }) => (
          <SwiperSlide className="relativ rounded-lg">
            <GatsbyImage
              className=""
              image={getImage(node)}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="slide"
            />
            <div className="absolute h-full text-center w-full px-[0.5em] py-[2em] md:py-[4em] text-white">
              <div className="h-full flex flex-col justify-between text-white">
                <p className="text-white text-[14px]  md:text-[22px] flex flex-col">
                  Introduction to CFD Trading
                </p>
                <p className="text-white text-[14px]  md:text-[22px] flex flex-col">
                  15 Lessons <span>(available in platform)</span>
                </p>
                <div className="flex items-center justify-center">
                  <BiTime className="fill-[#fff] mr-[0.2em]" />
                  <p className="text-white text-[14px] md:text-[22px] ">30’’20’</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default AcademySliderMobile
