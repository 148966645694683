import * as React from "react"
import SectionOne from "../components/homepage-sections/section1"
import SectionThree from "../components/homepage-sections/section3"
import SectionFour from "../components/homepage-sections/section4"
import SectionFive from "../components/homepage-sections/section5"
import SectionSix from "../components/homepage-sections/section6"
import Layout from "../components/layout"
import Seo from "../components/seo"
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <section>
        <SectionOne />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
      </section>
    </Layout>
  )
}

export default IndexPage
