import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const licences = [
  {
    leading: "Regulated by",
    name: "CySEC",
    text: "Cyprus Securities and Exchange Commission",
  },
  {
    leading: "Registrado da",
    name: "CONSOB",
    text: "Commissione Nazionale per le Societa e la Borsa",
  },
  {
    leading: "РЕГИСТРИРАНО В",
    name: "КФН",
    text: "Комисията за финансов надзоp",
  },
  {
    leading: "Registrovaná u",
    name: "CNB",
    text: "Česká Národní Banka",
  },
  {
    leading: "Autorizat de",
    name: "ASF",
    text: "Autoritatea de Supraveghere Financiară",
  },
]

const LicenceSliderMobile = () => {
    SwiperCore.use([Autoplay])
    
  const data = useStaticQuery(graphql`
    {
      bigImages: allFile(
        filter: {
          extension: { regex: "" }
          relativeDirectory: { eq: "licenceFlags" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

    return (
      <div className="onmobile mr-[-2em]">
        <Swiper
          className="licenceSlider"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          preloadImages={false}
          spaceBetween={15}
          slidesPerView={2.3}
        >
          {data.bigImages.edges.map(({ node }) => (
            <SwiperSlide className="">
              <div className="h-full relative">
                <GatsbyImage
                  className=""
                  image={getImage(node)}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="slide"
                />
                <div className="h-full bg-[#f8f8f8] rounded-b-2xl max-w-[224px]">
                  <div className="h-full pb-[0.5em] px-[0.3em]">
                    <p className="flex flex-col text-[12px] text-right pr-[0.5em] pb-[0.5em]">
                      Regulated by{" "}
                      <span className="text-black text-[14px]">CONSOB</span>
                    </p>
                    <p className="blueLink text-[12px] md:max-w-[70%] md:mx-auto">
                      Commissione Nazionale per le Società e la Borsa
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
}

export default LicenceSliderMobile
