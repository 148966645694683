import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import LicenceSliderMobile from "./homepage-components/LicenceSliderMobile"

const licences = [
  {
    leading: "Regulated by",
    name: "CySEC",
    text: "Cyprus Securities and Exchange Commission",
    flag: "../../images/cysec-flag.png",
  },
  {
    leading: "Registrado da",
    name: "CONSOB",
    text: "Commissione Nazionale per le Societa e la Borsa",
    flag: "../../images/italy-flag.png",
  },
  {
    leading: "РЕГИСТРИРАНО В",
    name: "КФН",
    text: "Комисията за финансов надзоp",
    flag: "../../images/bulgaria-flag.png",
  },
  {
    leading: "Registrovaná u",
    name: "CNB",
    text: "Česká Národní Banka",
    flag: "../../images/cehia-flag.png",
  },
  {
    leading: "Autorizat de",
    name: "ASF",
    text: "Autoritatea de Supraveghere Financiară",
    flag: "../../images/romania-flag.png",
  },
]

const SectionSix = () => {
  return (
    <section className="container py-[6em] pt-[2em] overflow-hidden">
      <div className="ondesktop">
        <div className="ondesktop center">
          <h2>Licensed by CySEC</h2>
          <p className="w-[85%] my-0 mx-auto">
            CAPEX is a pan-European broker provider, operated by Key Way
            Investments Limited, and licensed by CySEC (the financial regulatory
            of Cyprus). In addition, CAPEX is registered with other financial
            regulatory authorities from the UK, Germany and Poland and it
            adheres to the highest ethical standards in trading.
          </p>
        </div>
        <div className="flex  justify-between py-[6em]">
          {licences.map(item => {
            return (
              <div className="relative bg-[#f8f8f8] w-[19%]  rounded-2xl">
                <div>
                  <StaticImage
                    className="rounded-lg"
                    src={"../../images/cysec-flag.png"}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt=""
                  />
                </div>
                <div className="text-center p-[1em]">
                  <p>{item.leading}</p>
                  <p>{item.name}</p>
                </div>
                <div className="center w-[80%] my-0 mx-auto pb-[1em]">
                  <p className="blueLink font-medium text-[14px]">
                    {item.text}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="onmobile container px-0">
        <div>
          <div className="bg-[#f8f8f8]  rounded-2xl flex items-center justify-between">
            <div className="self-start">
              <StaticImage
                className="rounded-lg"
                src={"../../images/cysec-flag-mobile.png"}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
              />
            </div>
            <div className="text-right p-[1em]">
              <p className="font-medium text-[#000] text-[18px]">
                Licensed by CySEC
              </p>
              <p className="blueLink text-[14px]">
                Cyprus Securities and Exchange Commission
              </p>
            </div>
          </div>
          <div className="mt-8">
            <div>
              <div>
                <LicenceSliderMobile />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSix
