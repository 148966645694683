import React from "react"

const SectionFive = () => {
  return (
    <section className="ondesktop container pb-[5em]">
      <div className="center">
        <h2>There’s more than meets the eye</h2>
      </div>
      <div className="flex  justify-between">
        <div className="bg-[#f8f8f8] w-[31%] p-[3em] rounded-2xl">
          <p className="uppercase blueLink mb-[2em]">We put safety first!</p>
          <p>
            Our security systems and measures always keep your data and funds
            safe.
          </p>
        </div>
        <div className="bg-[#f8f8f8] w-[31%] p-[3em] rounded-2xl">
          <p>
            We designed our trading conditions to meet the needs of even the
            most sophisticated traders!{" "}
            <span className="blueLink text-[18px]">Competitive spreads</span>,{" "}
            <span className="blueLink text-[18px]">fast execution</span>, and
            <span className="blueLink text-[18px]"> price transparency</span> at
            all times.
          </p>
        </div>
        <div className="bg-[#f8f8f8] w-[31%] p-[3em] rounded-2xl">
          <p>
            Eight different types of assets classes for you to trade:{" "}
            <span className="blueLink text-[18px]">Forex</span>,{" "}
            <span className="blueLink text-[18px]">Stocks</span>,{" "}
            <span className="blueLink text-[18px]">Indices</span>,{" "}
            <span className="blueLink text-[18px]">ETFs</span>,{" "}
            <span className="blueLink text-[18px]">Commodities</span>,{" "}
            <span className="blueLink text-[18px]">Cryptocurrencies</span>, and{" "}
          </p>
        </div>
      </div>
    </section>
  )
}

export default SectionFive
