import * as React from "react"
import { Popover, Transition } from "@headlessui/react"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const ToolsItems = ({ data }) => {
  const { name, text } = data

  return (
    <div className="lg:hidden w-full px-0">
      <div className="w-full mx-auto bg-white rounded-2xl">
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button className="flex justify-between py-[2em] w-full text-left border-t botder-b border-[#e2e2e2]">
                <span className="blueLink text-[18px] ml-[1em]">{name}</span>

                <IoMdArrowDropdown
                  className={classNames(
                    open ? "rotate-180" : "",
                    "ml-2 mr-[1em] mt-2 h-7 w-7  transition delay-0  ease-in-out fill-[#0995dd] group-hover:fill-[#0995dd]"
                  )}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                show={open}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="pt-4 pb-[2em] text-sm text-gray-500 ml-[2em]">
                  <div>
                    <p className="blackLink text-[16px]">{text}</p>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  )
}

export default ToolsItems
