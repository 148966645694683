import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown"
import { BiTime } from "@react-icons/all-files/bi/BiTime"
import AcademySliderMobile from "./homepage-components/AcademySliderMobile"
const SectionThree = () => {
  return (
    <section className="w-full lg:bg-[#f8f8f8]">
      <div className="container flex flex-row items-center justify-between lg:py-[8em] overflow-hidden">
        <div className="w-full lg:w-[45%]">
          <div className="ondesktop">
            <h2 className="mb-0">Knowledge is power.</h2>
            <h3 className="mb-0">When it comes to your money - </h3>
            <h3 className="font-medium">it’s priceless. </h3>
          </div>
          <div className="onmobile mb-[3em]">
            <AcademySliderMobile />
          </div>
          <div className="mb-[4em] text-center lg:text-left">
            <p>
              An investment in education always pays the best interest. Get
              access to the right resources, tools, and intel so you can trade
              the markets with all the information at hand!
            </p>
          </div>
          <div className="text-center lg:text-left flex justify-center items-center lg:justify-start">
            <a className="blueLink flex items-center justify-start w-[fit-content]">
              Sign up & enjoy our in-platform trading lessons
            </a>
            <IoMdArrowDropdown className="xs:hidden -rotate-90 mr-[2em] h-7 w-7 mt-1 text-secondaryColor transition delay-0 ease-in-out group-hover:text-secondaryColor" />
          </div>
          <div className="onmobile pt-[3em] center">
            <StaticImage
              height={500}
              src="../../images/phones-section-3.png"
              quality={95}
              objectFit="contain"
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
        </div>
        <div className="w-[45%] ondesktop">
          <div className="bg-[#fff] flex items-center justify-between mb-[.5em] py-[1.5em] px-[2em] rounded-lg">
            <div>
              <p className="blueLink uppercase text-[18px]">
                Introduction to CFD Trading
              </p>
              <p className="text-[16px]">
                15 Lessons{" "}
                <span className="text-[#a6a6a6]">(Available in platform)</span>
              </p>
            </div>
            <div>
              <p className="flex items-center">
                <BiTime className="fill-[#a6a6a6] mr-[0.2em]" />
                <span className="text-[#a6a6a6]">30"20'</span>
              </p>
            </div>
          </div>
          <div className="bg-[#fff] flex items-center justify-between mb-[.5em] py-[1.5em] px-[2em] rounded-lg">
            <div>
              <p className="blueLink uppercase text-[18px]">
                Introduction to CFD Trading
              </p>
              <p className="text-[16px]">
                15 Lessons{" "}
                <span className="text-[#a6a6a6]">(Available in platform)</span>
              </p>
            </div>
            <div>
              <p className="flex items-center">
                <BiTime className="fill-[#a6a6a6] mr-[0.2em]" />
                <span className="text-[#a6a6a6]">30"20'</span>
              </p>
            </div>
          </div>
          <div className="bg-[#fff] flex items-center justify-between mb-[.5em] py-[1.5em] px-[2em] rounded-lg">
            <div>
              <p className="blueLink uppercase text-[18px]">
                Introduction to CFD Trading
              </p>
              <p className="text-[16px]">
                15 Lessons{" "}
                <span className="text-[#a6a6a6]">(Available in platform)</span>
              </p>
            </div>
            <div>
              <p className="flex items-center">
                <BiTime className="fill-[#a6a6a6] mr-[0.2em]" />
                <span className="text-[#a6a6a6]">30"20'</span>
              </p>
            </div>
          </div>
          <div className="bg-[#fff] flex items-center justify-between py-[1.5em] px-[2em] rounded-lg">
            <div>
              <p className="blueLink uppercase text-[18px]">
                Introduction to CFD Trading
              </p>
              <p className="text-[16px]">
                15 Lessons{" "}
                <span className="text-[#a6a6a6]">(Available in platform)</span>
              </p>
            </div>
            <div>
              <p className="flex items-center">
                <BiTime className="fill-[#a6a6a6] mr-[0.2em]" />
                <span className="text-[#a6a6a6]">30"20'</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionThree
